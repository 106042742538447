import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Colours,
  CopyOrShare,
  dateFormat,
  Icon,
  IconButton,
  Label,
  Money,
  Pill,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ProductIcon,
  Products,
  Progress,
  ProgressIndicator,
  Router,
  ScrollArea,
  ScrollBar,
  Switch,
  TextField,
  timeFormat
} from '@/Components'
import { AccountType } from '@/Enums/AccountType'
import { MarketingLayout } from '@/Layouts'
import { ParallaxText } from '@/Layouts/GetStartedLayout'
import { AllanGray, FNB, OneNvest, Satrix, Sygnia, TenX } from '@/Layouts/Logos'
import { cn } from '@/Lib'
import { Definitions } from '@/Pages/Marketing/Definition'
import { FAQ } from '@/Pages/Marketing/Pricing'
import { Account } from '@/types/account'
import { usePage } from '@inertiajs/react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import {
  AnimatePresence,
  motion,
  stagger,
  useAnimate,
  usePresence
} from 'framer-motion'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Toaster } from 'sonner'

type Props = {
  definition: Definitions
}

Page.layout = (page: ReactNode) => <MarketingLayout>{page}</MarketingLayout>

export default function Page() {
  return (
    <div className='w-full'>
      <Hero />
      <LogoBanner />
      <HowItWorks />
      <BlueBanner />
      <StrongFoundations />
      <Pricing />
      <FAQ />
    </div>
  )
}

function Hero() {
  const { definition } = usePage<Props>().props

  switch (definition) {
    case Definitions.smugllionaire:
      return <Smugllionaire />
    case Definitions.tfsayayness:
      return <Tfsayayness />
    case Definitions.investavert:
      return <Investavert />
    case Definitions.richtirement:
      return <Richtirement />
  }

  return (
    <section className='flex min-h-[calc(100svh-300px)] w-full items-center p-4 md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-500px)]'>
      <div className='mx-auto flex w-full max-w-5xl flex-col gap-4 lg:flex-row'>
        <div className='contents justify-center lg:flex lg:flex-col'>
          <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-strong'>
            The simple way to grow your money
          </h1>
          <p className='mt-4 max-w-prose text-2xl'>
            Zero jargon. Zero paperwork. Zero hidden fees.{' '}
            <br className='hidden lg:block' />
            More zeros in your bank account.
          </p>
          <ScrollArea className='mt-6 pb-3 lg:hidden'>
            <ScrollBar orientation={'horizontal'} />
            <div className='flex gap-2'>
              {[
                {
                  icon: Products.emergency_savings,
                  colour: Colours.green,
                  title: 'Emergency savings',
                  description:
                    'Protect your long term investments from short term hiccups.',
                  href: route('marketing.products.show', {
                    page: 'emergency-savings'
                  })
                },
                {
                  icon: Products.tfsa,
                  colour: Colours.sky,
                  title: 'Tax-free investments',
                  description:
                    'Safely and securely invest long term, tax-free.',
                  href: route('marketing.products.show', { page: 'tfsa' })
                },
                {
                  icon: Products.kids,
                  colour: Colours.indigo,
                  title: 'Kids accounts',
                  description:
                    'The perfect way to set your children up for a financially secure future.',
                  href: route('marketing.products.show', {
                    page: 'kids-accounts'
                  })
                },
                {
                  icon: Products.savings_pots,
                  colour: Colours.purple,
                  title: 'Savings pots',
                  description:
                    'Set money aside for specific purposes, add targets and track progress.',
                  href: route('marketing.products.show', {
                    page: 'savings-pots'
                  })
                }
              ].map((tab) => (
                <Router key={tab.icon} href={tab.href}>
                  <Card
                    role='presentation'
                    className='h-full w-max gap-2 border-8 border-base/30 p-3'
                  >
                    <div className='flex w-full items-center gap-4 p-3 pb-0'>
                      <ProductIcon shapes={tab.icon} colour={tab.colour} />
                      <h5 className='text-xl font-medium text-strong'>
                        {tab.title}
                      </h5>
                    </div>
                    <p className='max-w-72 p-3'>{tab.description}</p>
                  </Card>
                </Router>
              ))}
            </div>
          </ScrollArea>

          <div className='my-6 flex gap-2 sm:mt-10'>
            <Router
              href={route('login')}
              className='flex w-full items-center rounded-full bg-slate-100 p-1 hover:bg-slate-200 sm:max-w-max lg:p-2'
            >
              <span className='mx-auto text-xl font-medium leading-none text-strong sm:ml-12 sm:mr-8'>
                Sign up
              </span>
              <div className='flex items-center justify-center rounded-full bg-green-400 p-6'>
                <Icon className='size-8 text-3xl leading-[30px] text-white'>
                  arrow_right_alt
                </Icon>
              </div>
            </Router>
            <Router
              href='#first'
              className='flex min-w-max items-center justify-center rounded-full bg-white p-2 text-center hover:bg-slate-50 sm:max-w-max sm:px-10'
            >
              <span className='text-xl font-medium leading-none text-primary'>
                Learn more
              </span>
            </Router>
          </div>
        </div>
        <div className='relative hidden w-full flex-col justify-center gap-6 overflow-hidden lg:flex lg:max-w-xs'>
          <motion.div
            className='absolute inset-x-0 top-0 z-10 h-1/3 bg-gradient-to-b from-white from-40% to-transparent'
            key='hide-the-scroller'
          />
          <HeroScroller
            tabs={[
              {
                icon: Products.savings_pots,
                colour: Colours.purple,
                title: 'Savings pots',
                description:
                  'Set money aside for specific purposes, add targets and track progress.',
                href: route('marketing.products.show', {
                  page: 'savings-pots'
                })
              },
              {
                icon: Products.emergency_savings,
                colour: Colours.green,
                title: 'Emergency savings',
                description:
                  'Protect your long term investments from short term hiccups.',
                href: route('marketing.products.show', {
                  page: 'emergency-savings'
                })
              },
              {
                icon: Products.tfsa,
                colour: Colours.sky,
                title: 'Tax-free investments',
                description: 'Safely and securely invest long term, tax-free.',
                href: route('marketing.products.show', { page: 'tfsa' })
              },
              {
                icon: Products.kids,
                colour: Colours.indigo,
                title: 'Kids accounts',
                description:
                  'The perfect way to set your children up for a financially secure future.',
                href: route('marketing.products.show', {
                  page: 'kids-accounts'
                })
              }
            ]}
          />
          <motion.div
            className='absolute inset-x-0 bottom-0 z-10 h-1/3 bg-gradient-to-b from-transparent to-white to-40%'
            key='hide-the-scroller'
          />
        </div>
      </div>
    </section>
  )
}

function HeroScroller({
  tabs
}: {
  tabs: {
    icon: Products
    colour: Colours
    title: string
    description: string
    href: string
  }[]
}) {
  const intervalRef = useRef<NodeJS.Timeout>()
  const [activeTabs, setActiveTabs] = useState<number[]>([0, 1, 2])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setActiveTabs((prev) => {
        let nextTab = prev[prev.length - 1] + 1
        if (nextTab % 4 == 0) nextTab = 0
        let newTabs = prev

        newTabs.push(nextTab)
        newTabs = newTabs.slice(1)
        return newTabs
      })
    }, 5000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [activeTabs])

  return (
    <div className='space-y-2'>
      <AnimatePresence mode='popLayout'>
        {activeTabs.map((tab) => {
          const currentTab = tabs[tab]
          return (
            <motion.div
              key={currentTab.icon}
              layout
              initial={{ opacity: 0, y: 250 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -100 }}
              transition={{ layout: { duration: 0.3, ease: 'easeInOut' } }}
            >
              <Router href={currentTab.href}>
                <Card
                  role='presentation'
                  className='w-full gap-2 border-8 border-base/30 p-3'
                >
                  <div className='flex w-full items-center gap-4 p-3 pb-0'>
                    <ProductIcon
                      shapes={currentTab.icon}
                      colour={currentTab.colour}
                    />
                    <h5 className='text-xl font-medium text-strong'>
                      {currentTab.title}
                    </h5>
                  </div>
                  <p className='p-3'>{currentTab.description}</p>
                </Card>
              </Router>
            </motion.div>
          )
        })}
      </AnimatePresence>
    </div>
  )
}

function Smugllionaire() {
  return (
    <>
      <section className='flex min-h-[calc(100svh-430px)] w-full items-center bg-rose-500 p-4 text-white md:min-h-[calc(100dvh-450px)] lg:min-h-[calc(100dvh-450px)]'>
        <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
          <div className='contents justify-center sm:flex sm:flex-col'>
            <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
              Smug-llionaire
            </h1>
            <dfn
              title='Smug-llionaire'
              className='mt-4 max-w-prose text-2xl not-italic'
            >
              [ smuhg-l-y<span className='italic'>uh</span>-
              <span className='font-semibold'>nair</span> ]
            </dfn>
            <p className='font-semibold italic'>noun</p>
            <p className='mt-4 max-w-prose px-4'>
              When you become wealthy from investments you made when you where
              younger and the enormous self-satisfaction you gain from having
              made those smart decisions.
            </p>
            <Router href='#first' className='mt-8 font-medium'>
              Learn more about Fynbos Money
            </Router>
          </div>
        </div>
      </section>
      <section className='bg-slate-100 p-4 py-20'>
        <div className='mx-auto flex w-full flex-col items-center justify-center gap-6 text-pretty text-center sm:flex-row'>
          <h3 className='text-lg'>
            Start your journey to becoming a{' '}
            <span className='font-medium'>smug-llionaire</span> with a Fynbos
            tax-free savings account.
          </h3>
          <Router
            href={route('login')}
            className='flex w-full items-center justify-center rounded-full bg-blue-500 p-6 text-center hover:bg-blue-600 sm:max-w-max sm:px-20'
          >
            <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
              Sign up now
            </span>
          </Router>
        </div>
      </section>
    </>
  )
}

function Investavert() {
  return (
    <>
      <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-orange-500 p-4 text-white md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
        <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
          <div className='contents justify-center sm:flex sm:flex-col'>
            <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
              Invest-a-vert
            </h1>
            <dfn
              title='Smug-llionaire'
              className='mt-4 max-w-prose text-2xl not-italic'
            >
              [ <span className='font-semibold'>in</span>-vest-a-vurt ]
            </dfn>
            <p className='font-semibold italic'>noun</p>
            <p className='prose mt-4 max-w-prose px-4 text-2xl text-white prose-li:marker:text-white'>
              A person who relishes both:
              <ol>
                <li>
                  the introverted inner peace from making clever investment
                  choices AND
                </li>
                <li>
                  the extroverted swagger of getting rich off your clever
                  investment choices
                </li>
              </ol>
            </p>
            <Router href='#first' className='mt-8 font-medium'>
              Learn more about Fynbos Money
            </Router>
          </div>
        </div>
      </section>
      <section className='bg-slate-100 p-4 py-20'>
        <div className='mx-auto flex w-full flex-col items-center justify-center gap-6 text-pretty text-center sm:flex-row'>
          <h3 className='text-lg'>
            Start your journey to becoming an{' '}
            <span className='font-medium'>invest-a-vert</span> with a Fynbos
            tax-free savings account.
          </h3>
          <Router
            href={route('login')}
            className='flex w-full items-center justify-center rounded-full bg-blue-500 p-6 text-center hover:bg-blue-600 sm:max-w-max sm:px-20'
          >
            <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
              Sign up now
            </span>
          </Router>
        </div>
      </section>
    </>
  )
}

function Tfsayayness() {
  return (
    <>
      <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-indigo-500 p-4 text-white md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
        <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
          <div className='contents justify-center sm:flex sm:flex-col'>
            <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
              TFSA-yayness
            </h1>
            <dfn
              title='Smug-llionaire'
              className='mt-4 max-w-prose text-2xl not-italic'
            >
              [ tee-<span className='font-semibold'>ef</span>-es-a-yey-nis ]
            </dfn>
            <p className='font-semibold italic'>noun</p>
            <p className='mt-4 max-w-prose px-4'>
              A heightened sense of elation on maxing out your Tax Free Savings
              Account at R36 000. Often accompanied with the phrase &apos;Whoop,
              whoop!&apos; and &apos;YEAH!&apos;.
            </p>
            <Router href='#first' className='mt-8 font-medium'>
              Learn more about Fynbos Money
            </Router>
          </div>
        </div>
      </section>
      <section className='bg-slate-100 p-4 py-20'>
        <div className='mx-auto flex w-full flex-col items-center justify-center gap-6 text-pretty text-center sm:flex-row'>
          <h3 className='text-lg'>
            Start your journey to{' '}
            <span className='font-medium'>TFSA-yayness</span> with a Fynbos
            tax-free savings account.
          </h3>
          <Router
            href={route('login')}
            className='flex w-full items-center justify-center rounded-full bg-blue-500 p-6 text-center hover:bg-blue-600 sm:max-w-max sm:px-20'
          >
            <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
              Sign up now
            </span>
          </Router>
        </div>
      </section>
    </>
  )
}

function Richtirement() {
  return (
    <>
      <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-purple-500 p-4 text-white md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
        <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
          <div className='contents justify-center sm:flex sm:flex-col'>
            <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
              Rich-tirement
            </h1>
            <dfn
              title='Smug-llionaire'
              className='mt-4 max-w-prose text-2xl not-italic'
            >
              [ rich-<span className='font-semibold'>tahyuhr</span>-mu
              <span className='italic'>h</span>nt ]
            </dfn>
            <p className='font-semibold italic'>noun</p>
            <p className='mt-4 max-w-prose px-4'>
              Reaching retirement and becoming rich at the same time because of
              the clever money choices you made when you were young.
            </p>
            <Router href='#first' className='mt-8 font-medium'>
              Learn more about Fynbos Money
            </Router>
          </div>
        </div>
      </section>
      <section className='bg-slate-100 p-4 py-20'>
        <div className='mx-auto flex w-full flex-col items-center justify-center gap-6 text-pretty text-center sm:flex-row'>
          <h3 className='text-lg'>
            Start your journey to{' '}
            <span className='font-medium'>rich-tirement</span> with a Fynbos
            tax-free savings account.
          </h3>
          <Router
            href={route('login')}
            className='flex w-full items-center justify-center rounded-full bg-blue-500 p-6 text-center hover:bg-blue-600 sm:max-w-max sm:px-20'
          >
            <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
              Sign up now
            </span>
          </Router>
        </div>
      </section>
    </>
  )
}

function PlatformTabPortfolio({
  setDirty
}: {
  setDirty: Dispatch<SetStateAction<boolean>>
}) {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              delay: stagger(0.1),
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            delay: stagger(0.1),
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [animate, isPresent, safeToRemove, scope])

  const accounts: Account[] = useMemo(() => {
    return [
      {
        assets: [],
        sqid: 'es',
        userSqid: 'user.sqid',
        type: AccountType.emergencySavings,
        name: 'Emergency savings',
        icon: Products.emergency_savings,
        colour: Colours.green,
        target_amount: 1,
        automationRulesCount: 1,
        isEnabled: true
      },
      {
        assets: [],
        sqid: 'tfsa',
        userSqid: 'user.sqid',
        type: AccountType.tfsa,
        name: 'Tax-free investments',
        icon: Products.tfsa,
        colour: Colours.sky,
        target_amount: 1,
        automationRulesCount: 1,
        isEnabled: true
      },
      {
        assets: [],
        sqid: 'bi',
        userSqid: 'user.sqid',
        type: AccountType.savingsPots,
        name: 'Bike savings',
        icon: Products.savings_pots,
        colour: Colours.purple,
        target_amount: 1,
        automationRulesCount: 1,
        isEnabled: true
      },
      {
        assets: [],
        sqid: 'ni',
        userSqid: 'user.sqid',
        type: AccountType.cash,
        name: 'Not invested',
        icon: Products.contributions,
        colour: Colours.slate,
        target_amount: 1,
        automationRulesCount: 1,
        isEnabled: true
      }
    ]
  }, [])

  const balances: {
    sqid: string
    amount: number
  }[] = useMemo(() => {
    return [
      {
        sqid: 'es',
        amount: 32000
      },
      {
        sqid: 'tfsa',
        amount: 6400
      },
      {
        sqid: 'bi',
        amount: 4800
      },
      {
        sqid: 'ni',
        amount: 2000
      }
    ]
  }, [])

  const gains: {
    sqid: string
    amount: number
  }[] = useMemo(() => {
    return [
      {
        sqid: 'es',
        amount: 15
      },
      {
        sqid: 'tfsa',
        amount: 720
      },
      {
        sqid: 'bi',
        amount: 32
      },
      {
        sqid: 'ni',
        amount: 0
      }
    ]
  }, [])

  const [active, setActive] = useState<string[]>(
    balances.filter((val) => val.amount > 0).map((item) => item.sqid)
  )

  const [prevActive, setPrevActive] = useState<string[]>([])

  const _onToggleInvestment = useCallback<(value: string[]) => void>(
    (investments) => {
      setActive(investments)
      setPrevActive([])
      setDirty(true)
    },
    [setDirty]
  )

  useEffect(() => {
    // Reset the active balances when the balances changes (Normally when switching user)
    setActive(balances.filter((val) => val.amount > 0).map((item) => item.sqid))
  }, [balances])

  const _onToggleInvestmentBar = useCallback<(value: string) => void>(
    (investment) => {
      if (prevActive.length == 0) {
        setPrevActive(active)
        setActive([investment])
      } else {
        setActive(prevActive)
        setPrevActive([])
      }

      setDirty(true)
    },
    [active, prevActive, setDirty]
  )

  const total = useMemo(() => {
    return balances.reduce((prev, curr) => {
      if (active.includes(curr.sqid)) {
        return curr.amount + prev
      }
      return prev
    }, 0)
  }, [active, balances])

  const totalGain = useMemo(() => {
    let sign = '+'
    let amount = gains.reduce((prev, curr) => {
      if (active.includes(curr.sqid)) {
        return curr.amount + prev
      }
      return prev
    }, 0)

    if (amount < 0) {
      sign = '-'
      amount = Math.abs(amount)
    }

    return {
      amount,
      sign
    }
  }, [active, gains])

  const activeAccounts = useMemo(() => {
    return accounts
      .filter((account) => active.includes(account.sqid))
      .map((account) => ({
        ...account,
        balance: balances.find((bal) => bal.sqid == account.sqid)?.amount
      }))
  }, [accounts, active, balances])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center'>
      <Card id='card' role='presentation' className='max-w-96'>
        <CardHeader>
          <CardTitle>Portfolio</CardTitle>
        </CardHeader>
        <CardContent className='flex items-center justify-between'>
          <Money value={total} className='text-2xl font-medium' />
          <div className='flex items-center gap-3'>
            <Pill
              colour={totalGain.sign == '+' ? Colours.green : Colours.slate}
            >
              {totalGain.sign} <Money value={totalGain.amount} />
            </Pill>

            <Popover>
              <PopoverTrigger asChild>
                <IconButton>info</IconButton>
              </PopoverTrigger>
              <PopoverContent align='end' className='flex flex-col p-4 text-sm'>
                <p>
                  This shows how your balance has changed over time. It&apos;s
                  normal for investments to rise and fall in the short term due
                  to changes in the market.
                </p>
                <p>
                  Remember, you&apos;re investing for the long run, so
                  short-term dips don&apos;t necessarily mean a loss. Markets
                  naturally fluctuate, and those ups and downs are part of the
                  journey toward potential long-term growth.
                </p>
              </PopoverContent>
            </Popover>
          </div>
        </CardContent>
        <div className='mt-4 flex h-5 w-full rounded-full bg-nav p-1'>
          <AnimatePresence mode='wait'>
            {activeAccounts.length > 0 && (
              <motion.div
                key={'user.sqid'}
                layout
                initial={{ width: 0 }}
                animate={{ width: '100%' }}
                exit={{ width: 0 }}
                className='flex h-full w-full gap-0.5 overflow-hidden rounded-full'
                transition={{ layout: { duration: 0.3, ease: 'easeInOut' } }}
              >
                <AnimatePresence>
                  {activeAccounts.map((accounts) => (
                    <motion.button
                      key={accounts.sqid}
                      layout
                      onClick={() => _onToggleInvestmentBar(accounts.sqid)}
                      initial={{ flexGrow: 0 }}
                      animate={{ flexGrow: accounts.balance }}
                      exit={{ flexGrow: 0 }}
                      transition={{ duration: 0.3 }}
                      className={cn(
                        'flex h-full first:rounded-l-full last-of-type:rounded-r-full',
                        accounts.colour == Colours.rose && 'bg-rose-400',
                        accounts.colour == Colours.orange && 'bg-orange-400',
                        accounts.colour == Colours.yellow && 'bg-yellow-400',
                        accounts.colour == Colours.green && 'bg-green-400',
                        accounts.colour == Colours.sky && 'bg-sky-400',
                        accounts.colour == Colours.blue && 'bg-blue-400',
                        accounts.colour == Colours.indigo && 'bg-indigo-400',
                        accounts.colour == Colours.purple && 'bg-purple-400',
                        accounts.colour == Colours.slate && 'bg-slate-600'
                      )}
                    />
                  ))}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <ToggleGroup.Root
          id='month-toggle'
          className='mt-2 flex w-full flex-wrap gap-1'
          type='multiple'
          value={active}
          onValueChange={_onToggleInvestment}
          aria-label='Portfolio accounts'
        >
          {accounts.map((item) => (
            <ToggleGroup.Item
              key={item.sqid}
              className={cn(
                item.colour == Colours.rose &&
                  'data-[state=on]:bg-rose-50 data-[state=on]:text-rose-500',
                item.colour == Colours.orange &&
                  'data-[state=on]:bg-orange-50 data-[state=on]:text-orange-500',
                item.colour == Colours.yellow &&
                  'data-[state=on]:bg-yellow-50 data-[state=on]:text-yellow-500',
                item.colour == Colours.green &&
                  'data-[state=on]:bg-green-50 data-[state=on]:text-green-500',
                item.colour == Colours.sky &&
                  'data-[state=on]:bg-sky-50 data-[state=on]:text-sky-500',
                item.colour == Colours.blue &&
                  'data-[state=on]:bg-blue-50 data-[state=on]:text-blue-500',
                item.colour == Colours.indigo &&
                  'data-[state=on]:bg-indigo-50 data-[state=on]:text-indigo-500',
                item.colour == Colours.purple &&
                  'data-[state=on]:bg-purple-50 data-[state=on]:text-purple-500',
                item.colour == Colours.slate &&
                  'data-[state=on]:bg-nav data-[state=on]:text-medium',
                'group inline-flex max-w-max items-center gap-2 rounded-full px-2 py-1 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-600 disabled:cursor-not-allowed data-[disabled]:bg-slate-100 data-[state=off]:bg-slate-50 data-[disabled]:text-disabled data-[state=off]:text-disabled'
              )}
              value={item.sqid}
              aria-label='Left aligned'
            >
              <span
                className={cn(
                  'size-2 rounded-full group-data-[state=off]:bg-slate-300',
                  item.colour == Colours.rose &&
                    'data-[state=on]:bg-rose-50 group-data-[state=on]:bg-rose-400',
                  item.colour == Colours.orange &&
                    'data-[state=on]:bg-orange-50 group-data-[state=on]:bg-orange-400',
                  item.colour == Colours.yellow &&
                    'data-[state=on]:bg-yellow-50 group-data-[state=on]:bg-yellow-400',
                  item.colour == Colours.green &&
                    'data-[state=on]:bg-green-50 group-data-[state=on]:bg-green-400',
                  item.colour == Colours.sky &&
                    'data-[state=on]:bg-sky-50 group-data-[state=on]:bg-sky-400',
                  item.colour == Colours.blue &&
                    'data-[state=on]:bg-blue-50 group-data-[state=on]:bg-blue-400',
                  item.colour == Colours.indigo &&
                    'data-[state=on]:bg-indigo-50 group-data-[state=on]:bg-indigo-400',
                  item.colour == Colours.purple &&
                    'data-[state=on]:bg-purple-50 group-data-[state=on]:bg-purple-400',
                  item.colour == Colours.slate &&
                    'group-data-[state=on]:bg-slate-600'
                )}
              />
              <span className='text-xs'>{item.name}</span>
            </ToggleGroup.Item>
          ))}
        </ToggleGroup.Root>
      </Card>
    </div>
  )
}

function LogoBanner() {
  return (
    <section className='bg-slate-50 py-10'>
      <div className='mx-auto flex w-full sm:hidden'>
        <ParallaxText baseVelocity={-2}>
          <OneNvest />
          <AllanGray />
          <FNB />
          <Sygnia />
          <TenX />
          <Satrix />
        </ParallaxText>
      </div>
      <div className='mx-auto hidden w-full max-w-5xl justify-center gap-6 text-pretty text-center sm:flex'>
        <OneNvest />
        <AllanGray />
        <FNB />
        <Sygnia />
        <TenX />
        <Satrix />
      </div>
    </section>
  )
}

function BlueBanner() {
  return (
    <section className='bg-blue-600 p-4 py-20'>
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-6 text-pretty'>
        <h3 className='flex flex-col text-2xl font-bold uppercase text-inverted sm:text-4xl sm:leading-10'>
          Want to learn more about investing?
        </h3>
        <div className='contents sm:flex sm:items-center'>
          <p className='text-xl text-white'>
            Our learn page has everything you need to learn more about investing
            - simple, clear, and to the point.
          </p>
          <Router
            href={route('learn')}
            className='flex w-full items-center justify-center rounded-full bg-blue-500 p-6 text-center hover:bg-blue-700 sm:max-w-max sm:px-20'
          >
            <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
              Browse the learn library
            </span>
          </Router>
        </div>
      </div>
    </section>
  )
}

function HowItWorks() {
  const intervalRef = useRef<NodeJS.Timeout>()
  const [tabValue, setTabValue] = useState<string>('tab1')
  const [dirty, setDirty] = useState<boolean>(false)

  const tabs: Record<string, Record<string, string>> = useMemo(() => {
    return {
      tab1: {
        title: 'Quick and easy sign up',
        description:
          'Get started with just a few personal details - no paperwork, no hassle.'
      },
      tab2: {
        title: 'Choose how you want to save and invest',
        description: 'Set your targets and invest to grow your wealth.'
      },
      tab3: {
        title: 'Add money based on your targets',
        description:
          'Contribute at your own pace - whether it’s a lump sum or monthly deposits, you stay in control.'
      },
      tab4: {
        title: 'Keep track of your progress',
        description: 'Easily monitor your savings and investments.'
      },
      tab5: {
        title: 'Watch your money grow!',
        description:
          'Sit back and let compounding do the work - your savings and investments are built to grow.'
      }
    }
  }, [])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!dirty) {
        let nextTab = parseInt(tabValue.substring(3)) + 1
        if (nextTab % 6 == 0) nextTab = 1
        setTabValue(`tab${nextTab}`)
      }
    }, 5000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [dirty, tabValue])

  const _onTabChange = useCallback((value: string) => {
    setTabValue(value)
    setDirty(true)
  }, [])

  return (
    <section id='first' className='scroll-mt-20'>
      <div className='p-4 pb-10 pt-20 sm:pt-40 md:pb-20'>
        <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-10 text-pretty'>
          <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
            <span>how it works</span>
          </h3>
          <p className='mx-auto max-w-prose text-center text-2xl'>
            Taking control of your money is super simple and secure.
          </p>
        </div>
      </div>
      <div className=' w-full grid-cols-2 justify-center sm:min-h-max lg:grid lg:pb-40'>
        <AccordionPrimitive.Root
          type='single'
          value={tabValue}
          onValueChange={_onTabChange}
          className='col-span-2 flex max-w-lg flex-col gap-6 py-8 pl-4 pr-24 md:col-span-1 md:pl-4 lg:ml-auto lg:h-[27rem]'
        >
          {['tab1', 'tab2', 'tab3', 'tab4', 'tab5'].map((tab) => (
            <AccordionPrimitive.Item
              key={tab}
              value={tab}
              className='border-l-4 border-slate-200 pl-4 transition-colors duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus data-[state=open]:border-blue-500'
            >
              <AccordionPrimitive.Header>
                <AccordionPrimitive.Trigger className='w-full text-start text-2xl font-medium text-strong'>
                  {tabs[tab].title}
                </AccordionPrimitive.Trigger>
              </AccordionPrimitive.Header>
              <AccordionPrimitive.Content className='overflow-hidden transition-all data-[state=closed]:animate-accordionUp data-[state=open]:animate-accordionDown'>
                <span>{tabs[tab].description}</span>
              </AccordionPrimitive.Content>
            </AccordionPrimitive.Item>
          ))}
        </AccordionPrimitive.Root>
        <div
          className={cn(
            'relative col-span-2 flex min-h-[400px] w-full items-center justify-center overflow-hidden p-4 transition-colors duration-700 lg:col-span-1 lg:block lg:rounded-l-3xl lg:px-10',
            tabValue == 'tab1' && 'bg-green-100',
            tabValue == 'tab2' && 'bg-blue-100',
            tabValue == 'tab3' && 'bg-purple-100',
            tabValue == 'tab4' && 'bg-orange-100',
            tabValue == 'tab5' && 'bg-yellow-100'
          )}
        >
          <Toaster expand position='top-right' offset={16} />
          <AnimatePresence mode='wait'>
            {tabValue == 'tab1' && <PlatformTabSignup key='content-tab1' />}
            {tabValue == 'tab2' && <PlatformTabHowTo key='content-tab2' />}
            {tabValue == 'tab3' && (
              <PlatformTabAddMoney key='content-tab3' setDirty={setDirty} />
            )}
            {tabValue == 'tab4' && <PlatformTabKeepTrack key='content-tab4' />}
            {tabValue == 'tab5' && (
              <PlatformTabPortfolio key='content-tab5' setDirty={setDirty} />
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}

function PlatformTabAddMoney({
  setDirty
}: {
  setDirty: Dispatch<SetStateAction<boolean>>
}) {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              delay: stagger(0.1),
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            delay: stagger(0.1),
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [animate, isPresent, safeToRemove, scope])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center'>
      <Card id='card' className='max-w-96'>
        <DebitOrderForm setDirty={setDirty} />
      </Card>
      <Card id='card' className='mt-4 max-w-96'>
        <CardHeader>
          <CardTitle>Manual EFT</CardTitle>
        </CardHeader>

        <CardContent>
          Use your unique reference every time you make a manual contribution
          via EFT.
        </CardContent>
        <div className='flex w-full flex-col'>
          <Label className='mt-0'>Unique reference</Label>
          <CopyOrShare
            className='flex justify-between rounded-xl bg-nav p-3 hover:bg-nav-active'
            copyContent='https://fynbos.money/login'
          >
            FYN1234
          </CopyOrShare>
        </div>
      </Card>
    </div>
  )
}

function DebitOrderForm({
  setDirty
}: {
  setDirty: Dispatch<SetStateAction<boolean>>
}) {
  const [paused, setPaused] = useState<boolean>(true)

  const _onChangePaused = useCallback<(checked: boolean) => void>(
    (checked) => {
      setPaused(checked)
      setDirty(true)
    },
    [setDirty]
  )

  const debitStatus: ReactNode = useMemo(() => {
    const today = new Date()
    const currentDay = today.getDate()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()

    const deadline = new Date(
      currentYear,
      currentMonth,
      currentDay + 3,
      12,
      0,
      0,
      0
    )
    const target = new Date(currentYear, currentMonth, currentDay + 4)

    if (!paused) {
      return (
        <span>
          Your debit order is <span className='font-medium'>paused</span>.
          Re-enable it before{' '}
          <span className='font-medium'>{timeFormat(deadline)}</span> on{' '}
          <span className='font-medium'>{dateFormat(deadline)}</span> to resume
          on <span className='font-medium'>{dateFormat(target)}</span>.
        </span>
      )
    }

    return (
      <span>
        Your next debit order of <Money className='font-medium' value={500} />{' '}
        will run on <span className='font-medium'>{dateFormat(target)}</span>,
        from your bank account ending{' '}
        <span className='font-medium'>{4431}</span>.
      </span>
    )
  }, [paused])

  return (
    <>
      <CardHeader className='items-center justify-between'>
        <CardTitle>Debit order</CardTitle>
        <Switch
          id='contribution-paused'
          checked={paused}
          onCheckedChange={_onChangePaused}
        />
        <label htmlFor='contribution-paused' className='sr-only'>
          Debit order active
        </label>
      </CardHeader>
      <CardContent>{debitStatus}</CardContent>
    </>
  )
}

function PlatformTabKeepTrack() {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  const percentages = useMemo(() => {
    const recommendedSavings = 20000
    const external = 5000
    const fynbosSavings = 10000
    const total = 15000

    return {
      total: (total / recommendedSavings) * 100,
      external: (external / total) * 100,
      fynbos: (fynbosSavings / total) * 100
    }
  }, [])

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              delay: stagger(0.1),
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            delay: stagger(0.1),
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [
    animate,
    isPresent,
    percentages.external,
    percentages.fynbos,
    safeToRemove,
    scope
  ])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center gap-4'>
      <Card id='card' className='max-w-96'>
        <div className='flex w-full items-center gap-4 p-3 pb-0'>
          <ProductIcon shapes={0b0111_0111_1111_1111} colour={Colours.purple} />
          <h5 className='text-xl font-medium text-strong'>Bike savings</h5>
        </div>

        <Progress value={6000} max={15000} className='mt-4 bg-nav'>
          <ProgressIndicator colour={Colours.purple}></ProgressIndicator>
        </Progress>
      </Card>
      <Card id='card' className='max-w-96'>
        <div className='flex w-full items-center gap-4 p-3 pb-0'>
          <ProductIcon shapes={0b1001_0100_0010_1001} colour={Colours.indigo} />
          <h5 className='text-xl font-medium text-strong'>Car maintenance</h5>
        </div>

        <Progress value={14000} max={15000} className='mt-4 bg-nav'>
          <ProgressIndicator colour={Colours.indigo}></ProgressIndicator>
        </Progress>
      </Card>
      <Card id='card' className='max-w-96'>
        <div className='flex w-full items-center gap-4 p-3 pb-0'>
          <ProductIcon shapes={0b0110_1001_1001_0110} colour={Colours.yellow} />
          <h5 className='text-xl font-medium text-strong'>Holiday fund</h5>
        </div>

        <Progress value={2000} max={15000} className='mt-4 bg-nav'>
          <ProgressIndicator colour={Colours.yellow}></ProgressIndicator>
        </Progress>
      </Card>
    </div>
  )
}

function PlatformTabSignup() {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              delay: stagger(0.1),
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            delay: stagger(0.1),
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [animate, isPresent, safeToRemove, scope])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center'>
      <Card id='card' className='flex max-w-96 flex-col gap-2'>
        <CardHeader>
          <CardTitle>Your details</CardTitle>
        </CardHeader>
        <TextField
          disabled
          prefixIcon={<Icon>face</Icon>}
          placeholder='First name'
        />
        <TextField
          disabled
          prefixIcon={<Icon>smartphone</Icon>}
          placeholder='Mobile number'
        />
        <TextField
          disabled
          prefixIcon={<Icon>badge</Icon>}
          placeholder='ID number'
        />
      </Card>
    </div>
  )
}

function PlatformTabHowTo() {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              delay: stagger(0.1),
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            delay: stagger(0.1),
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [animate, isPresent, safeToRemove, scope])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center gap-4'>
      <Card id='card' className='flex max-w-96 flex-col gap-2'>
        <CardHeader>
          <CardTitle>Savings</CardTitle>
        </CardHeader>
        <CardContent className='flex gap-2'>
          <ProductIcon
            shapes={Products.emergency_savings}
            colour={Colours.green}
          />
          <p>Protect your long term investments from short term hiccups.</p>
        </CardContent>
        <CardContent className='flex gap-2'>
          <ProductIcon shapes={Products.savings_pots} colour={Colours.purple} />
          <p>
            Set money aside for specific purposes, add targets and track
            progress.
          </p>
        </CardContent>
      </Card>
      <Card id='card' className='flex max-w-96 flex-col gap-2'>
        <CardHeader>
          <CardTitle>Tax-free investments</CardTitle>
        </CardHeader>
        <CardContent className='flex gap-2'>
          <ProductIcon shapes={Products.tfsa} colour={Colours.sky} />
          <p>Safely and securely invest long term, tax-free.</p>
        </CardContent>
      </Card>
    </div>
  )
}

function StrongFoundations() {
  return (
    <section className='bg-slate-50 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty md:gap-20'>
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          <span>Why Fynbos?</span>
        </h3>
        <div className='grid grid-cols-2 gap-8'>
          <div className='col-span-full flex w-full flex-col gap-4 rounded-card bg-indigo-200 p-6 sm:gap-10 sm:p-10 md:col-span-1'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Proven strategies for long-term growth
            </h4>
            <p className='text-xl sm:text-2xl'>
              Our platform uses a tried-and-trusted methodology of low fee,
              tax-free investing paired with a high yield emergency savings
              account.
            </p>
          </div>
          <div className='col-span-full flex w-full flex-col gap-4 rounded-card bg-blue-200 p-6 sm:gap-10 sm:p-10 md:col-span-1'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Trusted partnerships and expert guidance
            </h4>
            <p className='text-xl sm:text-2xl'>
              We make it simple to start investing without needing to understand
              all the jargon or build a complicated personal finance plan or
              budget.
            </p>
          </div>
          <div className='col-span-full flex w-full flex-col gap-4 rounded-card bg-orange-200 p-6 sm:gap-10 sm:p-10'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Commitment to quality, simplicity, and transparency
            </h4>
            <p className='text-xl sm:text-2xl'>
              Our easy-to-use platform makes getting started stupidly simple,
              and our fees are all 100% transparent. You really might start to
              love investing.
            </p>
          </div>
        </div>
        <Router
          href={route('login')}
          className='flex w-full items-center justify-center rounded-full bg-yellow-400 p-6 text-center hover:bg-yellow-500 sm:max-w-max sm:px-20'
        >
          <span className='mx-auto text-lg font-medium leading-none text-strong lg:text-xl'>
            Sign up now
          </span>
        </Router>
      </div>
    </section>
  )
}

function Pricing() {
  return (
    <section id='pricing' className='bg-white p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          pricing
        </h3>
        <h4 className='flex flex-col text-center text-2xl font-medium text-medium'>
          The cost? No arms. No legs. No hidden fees. Just 1 monthly
          subscription no matter how you invest.
        </h4>

        <div className='flex w-full flex-col gap-4 text-center lg:flex-row'>
          <div className='flex w-full flex-col items-center rounded-card bg-green-200 p-10 sm:p-20'>
            <ProductIcon
              shapes={Products.roots}
              colour={Colours.green}
              className='size-10'
            />
            <h4 className='mt-4 text-2xl font-medium text-strong sm:text-4xl'>
              Roots plan
            </h4>
            <span className='mt-2 text-2xl text-medium'>Free</span>
            <p className='mt-10 text-xl'>
              With the free Roots plan, there&apos;s no subscription fee. Every
              cent goes directly into your portfolio, giving you everything you
              need to start investing.
            </p>
            <Router
              href={route('login', { protea: false })}
              className='mt-10 flex w-full items-center justify-center rounded-full bg-green-500 p-6 text-center hover:bg-green-600 sm:max-w-max sm:px-20'
            >
              <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
                Start for free
              </span>
            </Router>
          </div>
          <div className='flex w-full flex-col items-center rounded-card bg-rose-200 p-10 sm:p-20'>
            <ProductIcon
              shapes={Products.protea}
              colour={Colours.rose}
              className='size-10'
            />
            <h4 className='mt-4 text-2xl font-medium text-strong sm:text-4xl'>
              Protea plan
            </h4>
            <span className='mt-2 text-2xl text-medium'>R 100 per month</span>
            <p className='mt-10 text-xl'>
              Get a complete savings and investment portfolio in one place for a
              simple monthly subscription, with everything from the Roots plan
              plus more.
            </p>
            <Router
              href={route('login', { protea: true })}
              className='mt-10 flex w-full items-center justify-center rounded-full bg-rose-400 p-6 text-center hover:bg-rose-500 sm:max-w-max sm:px-20'
            >
              <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
                Start with Protea
              </span>
            </Router>
          </div>
        </div>

        <Router
          href={route('marketing.pricing')}
          className='flex w-full items-center justify-center rounded-full bg-blue-500 p-6 text-center hover:bg-blue-600 sm:max-w-max sm:px-20'
        >
          <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
            View pricing page
          </span>
        </Router>
      </div>
    </section>
  )
}
